<template>
  <img v-if="num%6 === 0" src="../../assets/lxp/images/@tmp/@tmp_course_thumbnail_01.jpg" alt="">
  <img v-else-if="num%6 === 1" src="../../assets/lxp/images/@tmp/@tmp_course_thumbnail_02.jpg" alt="">
  <img v-else-if="num%6 === 2" src="../../assets/lxp/images/@tmp/@tmp_course_thumbnail_03.jpg" alt="">
  <img v-else-if="num%6 === 3" src="../../assets/lxp/images/@tmp/@tmp_course_thumbnail_04.jpg" alt="">
  <img v-else-if="num%6 === 4" src="../../assets/lxp/images/@tmp/@tmp_course_thumbnail_05.jpg" alt="">
  <img v-else-if="num%6 === 5" src="../../assets/lxp/images/@tmp/@tmp_course_thumbnail_06.jpg" alt="">
</template>

<script>


export default {
  name: "CourseThumb",
  props: {
    num: Number
  },
}
</script>