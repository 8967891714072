<template>
  <ul class="educourse-list">
    <li v-for="(item, index) in items" :key="index" class="list-item" style="margin-top: 10px">
      <div class="educourse">
        <div class="educourse-summary course-row" style="padding-bottom: 10px;cursor: pointer" @click="collapseOrExpand(index)">
          <article class="course-card">
            <div class="course-image">
              <figure class="image-figure">
                <img v-if="item.mstThumb" :src="getThumbUrl(item.mstThumb)" alt="">
                <CourseThumb
                    v-else
                    :num="item.crseMstSn"
                />
              </figure>
            </div>
            <div class="course-content">
              <div class="content-header">
                <h5 class="title">
                  {{ item.crseMstNm }}
                </h5>
              </div>
              <div :class="{'content-footer': isMobile}">
                <div class="content-meta">
                  <span class="text" :class="{'txt_line' : !isMobile, 'txt_multi' : isMobile}" style="color:var(--kb-gold)">{{ item.crseSumup }}</span>
                </div>
                <div class="content-meta" style="margin-top: 20px">
                  <span class="text">
                    {{ item.orgnzNm }}
                    {{ item.mstTotLrnPerid ? ' | '.concat(item.mstTotLrnPerid,'일') : '' }}
                    {{ item.crseMstMlg ? ' | 마일리지'.concat(item.crseMstMlg) : '' }}
                  </span>
                  <!--                  <span v-if="item.mstTotLrnPerid" class="text">{{ item.mstTotLrnPerid }}일</span>-->
                  <!--                  <span v-if="item.crseMstMlg" class="text">마일리지 {{ item.crseMstMlg }}</span>-->
                </div>
                <div v-if="isMobile" class="striped-list-wrap">
                  <ul class="striped-list">
                    <li class="striped-item">
                      <div class="kb-collapse" :class="{ 'is-active' : !showDists[index] }">
                        <div class="kb-collapse-toggle striped-row" style="background-color: white">
                          <div class="striped-column column-arrow" style="padding: 0px 0px;min-height: 0px"><i class="icon-arrow"></i></div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="course-actions" style="display: flex;justify-content: flex-end;flex-direction: column;margin-left: 10px">
              <div>
                <!--                <button class="kb-btn-like" style="border-radius: 100%;" :class="{'kb-not-like': !item.isLike }"><i-->
                <!--                    :class="{'icon-like': !item.isLike, 'icon-like-active': item.isLike}"-->
                <!--                    @click="toggleLike(index)"></i>-->
                <!--                </button>-->
              </div>
              <div v-if="!isMobile" class="striped-list-wrap">
                <ul class="striped-list">
                  <li class="striped-item">
                    <div class="kb-collapse" :class="{ 'is-active' : !showDists[index] }">
                      <div class="kb-collapse-toggle striped-row" :style="showDists[index] ? {'background-color':'lightgray'} : {}">
                        <div class="striped-column column-arrow"><i class="icon-arrow"></i></div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </article>
        </div>

        <template v-if="isMobile">
          <template v-if="showDists[index]">
            <template v-if="item.crseDists.length > 0">
              <template v-for="(dist, idx) in item.crseDists" :key="idx">
                <template v-if="isShowApply(dist)">
                  <div class="educourse-content" style="border: 0px">
                    <div class="educourse-summary">
                      <p class="title">{{ dist.crseNm }}</p>
                      <p class="status text-red">
                        <span>신청정원 {{
                            dist.peoplCnt > 0 ?
                                `${numberComma(dist.peoplCnt)}명` :
                                '-'
                          }}</span>
                        <span v-if="limitOver(dist)"> ・ 신청정원초과</span>
                      </p>
                    </div>
                    <div class="educourse-date">
                      <div class="date-item">
                        <div class="title">연수기간</div>
                        <div>
                          {{ timestampToDateFormat(dist.bgngDt, 'yyyy.MM.dd') }} -
                          {{ timestampToDateFormat(dist.endDt, 'yyyy.MM.dd') }}
                        </div>
                      </div>
                      <div class="date-item">
                        <div class="title">신청기간</div>
                        <div>
                          {{ timestampToDateFormat(dist.aplyBgngDt, 'yyyy.MM.dd') }} -
                          {{ timestampToDateFormat(dist.aplyEndDt, 'yyyy.MM.dd') }}
                        </div>
                      </div>
                    </div>
                    <div class="educourse-actions">
                      <button class="kb-btn-apply" @click="showApplyViewMobile(dist.distCrseSn, dist.canAplyYn)">연수신청</button>
                    </div>
                  </div>
                </template>
              </template>
            </template>
          </template>
        </template>
        <template v-else>
          <template v-if="showDists[index]">
            <div v-if="item.crseDists.length > 0" class="educourse-content">
              <div class="kb-table kb-table-round">
                <table>
                  <colgroup>
                    <col style="width:auto;">
                    <col style="width:280px;">
                    <col style="width:280px;">
                    <col style="width:150px;">
                    <col style="width:140px;">
                  </colgroup>
                  <thead>
                  <tr>
                    <th><span class="th-title">과정차수명</span></th>
                    <th><span class="th-title">연수기간</span></th>
                    <th><span class="th-title">신청기간</span></th>
                    <th><span class="th-title">신청정원</span></th>
                    <th><span class="th-title">&nbsp;</span></th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-for="(dist, idx) in item.crseDists" :key="idx">
                    <tr v-if="isShowApply(dist)" style="cursor: pointer" @click="showApplyView(dist.distCrseSn, dist.canAplyYn)">
                      <td><strong class="td-text">{{ dist.crseNm }}</strong></td>
                      <td><strong class="td-text">{{ timestampToDateFormat(dist.bgngDt, 'yyyy.MM.dd') }} ~
                        {{ timestampToDateFormat(dist.endDt, 'yyyy.MM.dd') }}</strong></td>
                      <td>
                        <strong v-if="dist.aplyBgngDt" class="td-text">{{
                            timestampToDateFormat(dist.aplyBgngDt, 'yyyy.MM.dd')
                          }} ~ {{ timestampToDateFormat(dist.aplyEndDt, 'yyyy.MM.dd') }}</strong>
                        <strong v-else class="td-text">-</strong>
                      </td>
                      <td><strong class="td-text">{{
                          dist.peoplCnt > 0 ?
                              `${numberComma(dist.peoplCnt)}명` :
                              '-'
                        }}</strong></td>
                      <td class="text-end">
                        <template  v-if="dist.canAplyYn != 'N'">
                            <router-link :to="{name: 'ApplyTrainView', params: {distCrseSn: dist.distCrseSn}}"
                                         class="td-link">
                              <template v-if="limitOver(dist)">
                                <strong class="td-text" style="color: red">신청정원초과</strong>
                              </template>
                              <template v-else>
                                <strong class="td-text">상세</strong><i class="icon-arrow"></i>
                              </template>
                            </router-link>
                        </template>
                        <template v-else>
                          <div class="td-link">
                              <template v-if="limitOver(dist)">
                                <strong class="td-text" style="color: red">신청정원초과</strong>
                              </template>
                              <template v-else>
                                <strong class="td-text">상세</strong><i class="icon-arrow"></i>
                              </template>
                          </div>
                        </template>
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </div>
            </div>
          </template>
        </template>
      </div>
    </li>
  </ul>
</template>

<script>


import {numberComma, timestampToDateFormat} from '@/assets/js/util';
import CourseThumb from '@/components/apply/CourseThumb';
import {getThumbUrl} from '@/assets/js/modules/course/course-common';
import navigationUtils from '@/assets/js/navigationUtils';
import {computed, ref, watch} from 'vue';
import {useRouter} from 'vue-router';
import {useStore} from 'vuex';
import {useAlert} from "@/assets/js/modules/common/alert";

export default {
  name: 'ApplyCourse',
  components: {CourseThumb},
  props: {
    items: Array,
    canAplyYn: String,
    pageNo: Number,
    dtlItem: Object,
  },
  emits: ['toggleLike'],
  setup(props, {emit}) {

    const store = useStore();
    const isMobile = navigationUtils.any();
    const currentTs = new Date().getTime();
    const router = useRouter();
    const showDists = ref([]);
    const showDistsSize = ref(10);
    const session = computed(() => store.state.auth.session);
    const {showMessage} = useAlert();

    const limitOver = (dist) => {
      return (dist.peoplCnt > 0 && dist.peoplCnt <= dist.aplyRegCnt);
    };

    const toggleLike = (index) => {
      emit('toggleLike', index);
    };

    const excludeDist = [202333, 202334];
    const isShowApply = (dist) => {
      if (props.canAplyYn !== 'N') {
        if (props.dtlItem.digiDeptTyDcd === '2112001' && session.value.susdyCdDcd === '2109001') {
          if (excludeDist.includes(dist.distCrseSn)) {
            return false;
          } else {
            // return (!limitOver(dist.peoplCnt, dist.aplyRegCnt) && dist.aplyBgngDt <= currentTs && dist.aplyEndDt > currentTs);
            return (dist.aplyBgngDt <= currentTs && dist.aplyEndDt > currentTs);
          }
        } else {
          // return (!limitOver(dist.peoplCnt, dist.aplyRegCnt) && dist.aplyBgngDt <= currentTs && dist.aplyEndDt > currentTs);
          return (dist.aplyBgngDt <= currentTs && dist.aplyEndDt > currentTs);
        }
      }
      return true;
    };

    const showApplyViewMobile = (key, cYn) => {
      if(cYn == "N"){
        showMessage('신청가능 대상자가 아닙니다.');
        return;
      }
      router.push({name: 'ApplyTrainView', params: {distCrseSn: key}});
    };

    const initShow = () => {
      // console.log(props.pageNo);
      showDists.value = [];
      for (let i = 0; i < showDistsSize.value; i++) {
        showDists.value[i] = false;
      }
    }

    const collapseOrExpand = (index) => {
      // console.log(index, showDists.value[index]);
      showDists.value[index] = !showDists.value[index];
      return showDists.value[index];
    }

    const showApplyView = (index, cYn) => {
      if(cYn == "N"){
        showMessage('신청가능 대상자가 아닙니다.');
        return;
      }

      // console.log(index);
      router.push({name: 'ApplyTrainView', params: {distCrseSn: index}});
    }

    watch (() => props.pageNo , () => {
      initShow()
    });

    return {
      limitOver,
      toggleLike,
      timestampToDateFormat,
      numberComma,
      isShowApply,
      getThumbUrl,
      isMobile,
      showApplyViewMobile,
      collapseOrExpand,
      showApplyView,
      showDists,
      showMessage
    };
  },
};
</script>

<style scoped>

.lxp-mobile-layout .course-row .course-actions {
  z-index: 1;
}

.txt_line {
  width: 1300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.txt_multi {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* 라인수 */
  -webkit-box-orient: vertical;
  word-wrap:break-word;
  line-height: 1.2em;
  height: 3.6em;
}

</style>
