<template>
  <main class="kb-main" id="kb-educourse" @click="closeToggles(toggles)">
    <LxpMobileHeader v-if="isMobile" :title="`${isSearch ? '' : '연간연수일정'.concat(' ',paging.totalCount,'건')}`">
      <template v-slot:left>
        <template v-if="isSearch">
          <div class="util util-back">
            <a href="javascript:" class="util-actions util-actions-back" @click="clickBack">
              <i class="icon-history-back"></i>
            </a>
          </div>
          <div class="util util-search-input">
            <input type="text" placeholder="연수신청 검색" v-model="search.crseNm" @keyup.enter="toggleSearch">
          </div>
        </template>
        <template v-else>
          <div class="util util-more">
            <a href="javascript:" class="util-actions util-actions-search" @click="headerToggle">
              <i class="icon-more"></i>
            </a>
            <div class="dropdown" :class="{'is-active': hToggle}" data-offset="header-left">
              <div class="dropdown-target">
                <div class="dropdown-box">
                  <ul class="dropdown-option-list">
                    <li class="dropdown-option-item">
<!--                      <file-down-link :file-key="guideFileKey" :add-link-class="['dropdown-option-link']" :add-text-class="['dropdown-option-text']" />-->
                    </li>
<!--                    <li class="dropdown-option-item"><a href="javascript:" class="dropdown-option-link is-active"><span-->
<!--                        class="dropdown-option-text">직무인증제 안내<i class="icon-download"></i></span></a></li>-->
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </template>
      </template>
      <template v-slot:right>
        <div class="util util-search">
          <a href="javascript:" class="util-actions util-actions-search" @click="toggleSearch">
            <i class="icon-search"></i>
          </a>
        </div>
      </template>
    </LxpMobileHeader>
    <lxp-main-header
        v-if="!isMobile"
        :show-title="true"
        :show-breadcrumb="true"
        title="연수신청">
      <template v-slot:inner>
        <div class="header-column">
          <div class="download">
<!--            <file-down-link :file-key="guideFileKey" :add-link-class="['download-link']" />-->
<!--            <a href="javascript:" class="download-link">직무인증제 안내<i class="icon-download"></i></a>-->
          </div>
        </div>
      </template>
      <template v-slot:action>
        <div class="d-flex justify-content-center align-items-center" style="margin-top: var(--kb-spacer-4)">
          <div class="header-tab">
            <nav class="kb-nav-round-tabs">
              <ul class="kb-nav-list">
                <li class="kb-nav-item">
                  <router-link :to="{name: 'ApplyAnnualMain'}" class="kb-nav-link">
                    <span class="kb-nav-text">집합</span>
                  </router-link>
                </li>
                <li class="kb-nav-item">
                  <router-link :to="{name: 'ApplyAnnual'}" class="kb-nav-link is-active">
                    <span class="kb-nav-text">통신·사이버</span>
                  </router-link>
                </li>
              </ul>
            </nav>
          </div>
          <div class="header-actions" style="margin-top: 0px; margin-left:  var(--kb-spacer-3)">
            <SortButtonSelect
                v-model="crseDate.year"
                v-model:toggle="toggles.year"
                title="년도"
                :options="yearOptions"
                sequence-key="value"
                name-key="display"
                :is-num="true"
                button-class="kb-btn kb-btn-outline kb-btn-actions"
                @update:toggle="closeToggles(toggles, 'year')"
                @selected="pagingFunc()"
            />
          </div>
        </div>
      </template>
    </lxp-main-header>
    <!-- main-header -->
    <div class="main-header">
      <!-- main-header > header-snb -->
      <div class="d-flex justify-content-center align-items-center" style="margin-top: var(--kb-spacer-2)">
      <div v-if="isMobile" class="header-snb">
        <nav class="snb" style="margin-top: 0px; height: 37px">
          <ul class="snb-list">
            <li class="snb-item" style="margin-right: 10px">
              <router-link :to="{name: 'ApplyAnnualMain'}" class="kb-nav-link">
                <button>집합</button>
              </router-link>
            </li>
            <li class="snb-item" style="margin-right: 0px">
              <router-link :to="{name: 'ApplyAnnual'}" class="kb-nav-link">
                <button class="is-active">통신·사이버</button>
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
      <div v-if="isMobile" class="header-snb">
        <nav class="snb" style="margin-top: 0px;">
          <ul class="snb-list">
            <li class="snb-item">
              <SortButtonSelect3
                  v-model="crseDate.year"
                  v-model:toggle="toggles.year"
                  title="년도"
                  :options="yearOptions"
                  sequence-key="value"
                  name-key="display"
                  :is-num="true"
                  button-class="kb-btn kb-btn-outline kb-btn-actions"
                  @update:toggle="closeToggles(toggles, 'year')"
                  @selected="pagingFunc()"
              />
            </li>
          </ul>
        </nav>
      </div>
      </div>
    </div>
    <!-- //main-header -->
    <!-- main-content -->
    <div class="main-content main-component">
      <div class="educourse-list-container">
        <!-- list-top -->
        <div class="list-top">
          <div class="top-column">
            <!-- list-top > list-sort -->
            <div class="list-sort">
              <div class="sort">
                <!--                <div class="kb-form-dropdown is-active">-->
                <SortButtonSelect3
                    v-if="isMobile"
                    v-model="search.orgnzCdDcd"
                    v-model:toggle="toggles.orgnzCdDcd"
                    title="주관처"
                    :options="orgnzCdOptions"
                    sequence-key="cd"
                    name-key="cdNm"
                    :is-all="true"
                    :width="200"
                    @update:toggle="closeToggles(toggles, 'orgnzCdDcd')"
                    @selected="pagingFunc()"
                />
                <SortButtonSelect
                    v-else
                    v-model="search.orgnzCdDcd"
                    v-model:toggle="toggles.orgnzCdDcd"
                    title="주관처"
                    :options="orgnzCdOptions"
                    sequence-key="cd"
                    name-key="cdNm"
                    :is-all="true"
                    :width="200"
                    @update:toggle="closeToggles(toggles, 'orgnzCdDcd')"
                    @selected="pagingFunc()"
                />
              </div>
              <div class="sort">
                <SortButtonSelect3
                    v-if="isMobile"
                    v-model="search.mediaCateCd"
                    v-model:toggle="toggles.mediaCateCd"
                    title="매체"
                    :options="cateOptions"
                    sequence-key="mediaCateCd"
                    name-key="mediaCateNm"
                    :is-all="true"
                    :width="200"
                    @update:toggle="closeToggles(toggles, 'mediaCateCd')"
                    @selected="pagingFunc()"
                />
                <SortButtonSelect
                    v-else
                    v-model="search.mediaCateCd"
                    v-model:toggle="toggles.mediaCateCd"
                    title="매체"
                    :options="cateOptions"
                    sequence-key="mediaCateCd"
                    name-key="mediaCateNm"
                    :is-all="true"
                    :width="200"
                    @update:toggle="closeToggles(toggles, 'mediaCateCd')"
                    @selected="pagingFunc()"
                />
              </div>
            </div>
          </div>
          <div v-if="!isMobile" class="top-column">
            <form @submit.prevent>
              <div class="kb-form-search">
                <div class="kb-form-search-status">
                  <span class="text">총 {{ paging.totalCount }}건</span>
                </div>
                <div class="kb-form-search-field">
                  <input v-model="search.crseNm" type="text" class="kb-form-search-input" placeholder="과정 검색" @keyup.enter="pagingFunc()">
                  <button class="kb-form-search-btn" @click="pagingFunc()"><i class="icon-search">검색</i></button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- //list-top -->
        <!-- educourse-list -->
        <ApplyCourse
            :items="items"
            :dtlItem="dtlItem"
            @toggleLike="actLike"
        />
      </div>
      <div class="pt-5">
        <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
      </div>
    </div>

  </main>

</template>

<script>

import LxpMainHeader from "@/components/common/LxpMainHeader";
import {computed, reactive, ref, watch} from 'vue';
import ApplyCourse from '@/components/apply/ApplyCourse';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import {useAlert} from '@/assets/js/modules/common/alert';
import {
  closeToggles,
  getDcdOptions,
  getListFunc,
  getValueByQuery,
  pushQuery,
  setFilterByQuery,
  getFunc
} from '@/assets/js/ui.init';
import {ACT_GET_CRSE_APPLY_LIST, ACT_GET_DIGITAL_DTL} from '@/store/modules/course/course';
import {insertCourseAct} from '@/assets/js/modules/course/course-common';
import {actTyCdDcds} from '@/assets/js/modules/common/common';
import SortButtonSelect from '@/components/common/SortButtonSelect';
import CommonPaginationFront from '@/components/CommonPaginationFront';
import navigationUtils from '@/assets/js/navigationUtils';
import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader';
import SortButtonSelect3 from '@/components/common/SortButtonSelect3';
// import FileDownLink from "@/components/common/FileDownLink";

export default {
  name: 'ApplyAnnual',
  components: {
    CommonPaginationFront,
    SortButtonSelect3,
    SortButtonSelect,
    ApplyCourse,
    LxpMainHeader,
    LxpMobileHeader,
    // FileDownLink,
  },
  setup(){
    const isMobile = navigationUtils.any();
    const calendarModal = ref(false);

    const store = useStore();
    const route = useRoute();
    const {showConfirm} = useAlert();
    const hToggle = ref(false);
    const isSearch = ref(false);
    const searchCnt = ref(0);

    const items = ref([]);
    const paging = reactive({
      pageNo: getValueByQuery(route.query, 'pageNo', true, 1),
      pageSize: 10,
      totalCount: 0
    });

    const search = reactive({
      crseNm: '',
      orgnzCdDcd: '',
      mediaCateCd: '',
    });

    const resetParams = () => {
      search.crseNm = '';
      search.orgnzCdDcd = '';
      search.mediaCateCd = '';
    };

    const dtlItem = reactive({
      lrnerId: 0,
      reqYn: '',
      digiDeptTyDcd: '',
      digiDeptTyNm: '',
      preYn: '',
      basicYn: '',
      advnYn: '',
    });

    const currentDate = new Date();
    const crseDate = reactive({
      year: getValueByQuery(route.query, 'crseYear', true, currentDate.getFullYear()),
    });

    const yearOptions = (() => {
      const options = [];
      for(let i = currentDate.getFullYear() + 1; i >= 2017 ; i--){
        options.push({value: i, display: `${i}년`});
      }
      return options;
    })();

    const toggles = reactive({
      orgnzCdDcd: false,
      mediaCateCd: false,
      year: false
    });

    const orgnzCdOptions = computed(() => getDcdOptions('2016'));
    const cateOptions = computed(() => store.state.code.cates.filter(x => x.mediaCateCd == '100000' || x.mediaCateCd == '300000'));

    setFilterByQuery(search, route.query);

    const mutItems = () => {
      items.value = items.value.map(x => ({...x, isLike: (x.myLikeCnt > 0)}))
    }

    const getCourseApplyList = () => {
      getListFunc(`course/${ACT_GET_CRSE_APPLY_LIST}`, {...search,...paging, crseYear: crseDate.year, yearRangeYn: 'Y', aplyRegYn: 'Y', annualYn: 'Y'}, items, paging, mutItems);
    }

    const getCourseDigital = () => {
      getFunc(`course/${ACT_GET_DIGITAL_DTL}`, {}, dtlItem, () => {});
    };

    const actLike = (index) => {
      // 좋아요 상태이면 confirm 창 노출
      if(items.value[index].isLike){
        showConfirm({
          text : `찜을 취소하시겠습니까?`,
          callback: () => {
            items.value[index].isLike = !items.value[index].isLike;
            insertCourseAct(actTyCdDcds.ACT_TY_CD_CANCEL_LIKE, {crseMstSn: items.value[index].crseMstSn});
          }
        });
      }else{
        items.value[index].isLike = !items.value[index].isLike;
        insertCourseAct(actTyCdDcds.ACT_TY_CD_LIKE, {crseMstSn: items.value[index].crseMstSn});
      }
    }

    const pagingFunc = (pageNo) => {
      if(pageNo){
        pushQuery({...search, crseYear: crseDate.year, pageNo});
      }else{
        pushQuery({...search, crseYear: crseDate.year});
      }
    }

    const headerToggle = () => {
      if (hToggle.value) {
        hToggle.value = false;
      } else {
        hToggle.value = true;
      }
    };

    const toggleSearch = () => {
      if (isSearch.value) {
        pagingFunc();
      } else {
        resetParams();
        isSearch.value = true;
        searchCnt.value = 0;
      }
    };

    const clickBack = () => {
      isSearch.value = false;
      hToggle.value = false;
      resetParams();
      if(Object.keys(route.query).length === 0) getCourseApplyList();
      else pushQuery({...search});
    };

    watch(() => route.query, () => {
      if (route.name === 'ApplyAnnual') {
        setFilterByQuery(search, route.query);
        paging.pageNo = getValueByQuery(route.query, 'pageNo', true, 1);
        getCourseApplyList();
      }
    });

    getCourseApplyList();
    getCourseDigital();

    return {
      calendarModal,
      items,
      paging,
      search,
      crseDate,
      toggles,
      yearOptions,
      orgnzCdOptions,
      cateOptions,
      pagingFunc,
      closeToggles,
      actLike,
      isMobile,
      hToggle,
      headerToggle,
      isSearch,
      toggleSearch,
      clickBack,
      searchCnt,
      dtlItem,
      guideFileKey: 'AnnualCourseList',
    }
  }
}
</script>
